import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import GQLCodeSnippet, { GQLOpen, GQLClose, GQLLine, GQLTab, GQLName, GQLKeyword, GQLAttribute, GQLOperator, GQLComment, GQLLink } from "../../components/GQLCodeSnippet/GQLCodeSnippet";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Health of ECDN server’s operational services
(ones that doesn’t affect content delivery).`}</p>
    <GQLCodeSnippet mdxType="GQLCodeSnippet">
      <GQLLine mdxType="GQLLine"><GQLKeyword mdxType="GQLKeyword">type</GQLKeyword> OperationsHealth <GQLOpen mdxType="GQLOpen" /></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Aggregated health of operational services.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># (ones that doesn't affect content delivery).</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># - GOOD if all services are in GOOD state.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># - WARNING if at least one is in WARNING and none of them are in POOR state.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># - POOR if at least one is in POOR state.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">aggregated</GQLName>: <GQLLink href="/ecdn-api-enums/Health" mdxType="GQLLink">Health</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Health of update service.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># - GOOD if update repositories are reachable.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># - POOR if update repositories are unreachable.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># If parent proxy feature is enabled:</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># - GOOD same as before and all parents are reachable.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># - WARNING update repositories are but not all parents are reachable.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># - POOR same as before or no parents are reachable.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">updates</GQLName>: <GQLLink href="/ecdn-api-enums/Health" mdxType="GQLLink">Health</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Health of ECDN software update.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># - GOOD if server has the latest ECDN version.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># - WARNING if server version isn't the latest but still supported.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># - POOR if server version isn't supported anymore.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">softwareVersion</GQLName>: <GQLLink href="/ecdn-api-enums/Health" mdxType="GQLLink">Health</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Health of API connection.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># - GOOD if ECDN API is reachable.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># - POOR if ECDN API is unreachable.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># If parent proxy feature is enabled:</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># - GOOD same as before and all parents are reachable.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># - WARNING ECDN API is but not all parents are reachable.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># - POOR same as before or no parents are reachable.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># On POOR status content delivery will continue to function properly, but ECDN</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># server won't be able to apply remote configurations or report status.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">apiConnection</GQLName>: <GQLLink href="/ecdn-api-enums/Health" mdxType="GQLLink">Health</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Health of metric collector service.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># - GOOD if service is up and running.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># - POOR if service isn't running.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">metrics</GQLName>: <GQLLink href="/ecdn-api-enums/Health" mdxType="GQLLink">Health</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Health of NTP service.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># - GOOD if ECDN server is in sync with NTP servers.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># - WARNING if ECDN server is in sync with at least one NTP server but not all of them.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># - POOR if:</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment">#   - ECDN server isn't in sync with any NTP servers.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment">#   - NTP service isn't running.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment">#   - Time skew is higher than 1 minute.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">ntp</GQLName>: <GQLLink href="/ecdn-api-enums/Health" mdxType="GQLLink">Health</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Health of log forwarding service. Null if log forwarding is disabled.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># - GOOD if ECDN server can forward logs.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># - POOR if ECDN server cannot forward logs.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># On POOR status log forwarder service might not be running.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">logForward</GQLName>: <GQLLink href="/ecdn-api-enums/Health" mdxType="GQLLink">Health</GQLLink></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Health of remote assistance service. Null if remote assistance is disabled.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># - GOOD if remote assistance is working fine.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># - POOR if remote assistance is working fine.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># On POOR status remote assistance service might not be running or remote</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># assistance server is unreachable.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">remoteAssistance</GQLName>: <GQLLink href="/ecdn-api-enums/Health" mdxType="GQLLink">Health</GQLLink></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Health of SNMP polling service. Null if SNMP polling is disabled.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># - GOOD if SNMP service is up and running.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># - POOR if SNMP service isn't running.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">snmp</GQLName>: <GQLLink href="/ecdn-api-enums/Health" mdxType="GQLLink">Health</GQLLink></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
      <GQLLine mdxType="GQLLine"><GQLClose mdxType="GQLClose" /></GQLLine>
    </GQLCodeSnippet>
    <h2>{`Fields`}</h2>
    <h3>{`aggregated: `}<a parentName="h3" {...{
        "href": "/ecdn-api-enums/Health"
      }}>{`Health`}</a>{`!`}</h3>
    <p>{`Aggregated health of operational services.
(ones that doesn’t affect content delivery).`}</p>
    <ul>
      <li parentName="ul">{`GOOD if all services are in GOOD state.`}</li>
      <li parentName="ul">{`WARNING if at least one is in WARNING and none of them are in POOR state.`}</li>
      <li parentName="ul">{`POOR if at least one is in POOR state.`}</li>
    </ul>
    <h3>{`updates: `}<a parentName="h3" {...{
        "href": "/ecdn-api-enums/Health"
      }}>{`Health`}</a>{`!`}</h3>
    <p>{`Health of update service.`}</p>
    <ul>
      <li parentName="ul">{`GOOD if update repositories are reachable.`}</li>
      <li parentName="ul">{`POOR if update repositories are unreachable.
If parent proxy feature is enabled:`}</li>
      <li parentName="ul">{`GOOD same as before and all parents are reachable.`}</li>
      <li parentName="ul">{`WARNING update repositories are but not all parents are reachable.`}</li>
      <li parentName="ul">{`POOR same as before or no parents are reachable.`}</li>
    </ul>
    <h3>{`softwareVersion: `}<a parentName="h3" {...{
        "href": "/ecdn-api-enums/Health"
      }}>{`Health`}</a>{`!`}</h3>
    <p>{`Health of ECDN software update.`}</p>
    <ul>
      <li parentName="ul">{`GOOD if server has the latest ECDN version.`}</li>
      <li parentName="ul">{`WARNING if server version isn’t the latest but still supported.`}</li>
      <li parentName="ul">{`POOR if server version isn’t supported anymore.`}</li>
    </ul>
    <h3>{`apiConnection: `}<a parentName="h3" {...{
        "href": "/ecdn-api-enums/Health"
      }}>{`Health`}</a>{`!`}</h3>
    <p>{`Health of API connection.`}</p>
    <ul>
      <li parentName="ul">{`GOOD if ECDN API is reachable.`}</li>
      <li parentName="ul">{`POOR if ECDN API is unreachable.
If parent proxy feature is enabled:`}</li>
      <li parentName="ul">{`GOOD same as before and all parents are reachable.`}</li>
      <li parentName="ul">{`WARNING ECDN API is but not all parents are reachable.`}</li>
      <li parentName="ul">{`POOR same as before or no parents are reachable.
On POOR status content delivery will continue to function properly, but ECDN
server won’t be able to apply remote configurations or report status.`}</li>
    </ul>
    <h3>{`metrics: `}<a parentName="h3" {...{
        "href": "/ecdn-api-enums/Health"
      }}>{`Health`}</a>{`!`}</h3>
    <p>{`Health of metric collector service.`}</p>
    <ul>
      <li parentName="ul">{`GOOD if service is up and running.`}</li>
      <li parentName="ul">{`POOR if service isn’t running.`}</li>
    </ul>
    <h3>{`ntp: `}<a parentName="h3" {...{
        "href": "/ecdn-api-enums/Health"
      }}>{`Health`}</a>{`!`}</h3>
    <p>{`Health of NTP service.`}</p>
    <ul>
      <li parentName="ul">{`GOOD if ECDN server is in sync with NTP servers.`}</li>
      <li parentName="ul">{`WARNING if ECDN server is in sync with at least one NTP server but not all of them.`}</li>
      <li parentName="ul">{`POOR if:`}
        <ul parentName="li">
          <li parentName="ul">{`ECDN server isn’t in sync with any NTP servers.`}</li>
          <li parentName="ul">{`NTP service isn’t running.`}</li>
          <li parentName="ul">{`Time skew is higher than 1 minute.`}</li>
        </ul>
      </li>
    </ul>
    <h3>{`logForward: `}<a parentName="h3" {...{
        "href": "/ecdn-api-enums/Health"
      }}>{`Health`}</a></h3>
    <p>{`Health of log forwarding service. Null if log forwarding is disabled.`}</p>
    <ul>
      <li parentName="ul">{`GOOD if ECDN server can forward logs.`}</li>
      <li parentName="ul">{`POOR if ECDN server cannot forward logs.
On POOR status log forwarder service might not be running.`}</li>
    </ul>
    <h3>{`remoteAssistance: `}<a parentName="h3" {...{
        "href": "/ecdn-api-enums/Health"
      }}>{`Health`}</a></h3>
    <p>{`Health of remote assistance service. Null if remote assistance is disabled.`}</p>
    <ul>
      <li parentName="ul">{`GOOD if remote assistance is working fine.`}</li>
      <li parentName="ul">{`POOR if remote assistance is working fine.
On POOR status remote assistance service might not be running or remote
assistance server is unreachable.`}</li>
    </ul>
    <h3>{`snmp: `}<a parentName="h3" {...{
        "href": "/ecdn-api-enums/Health"
      }}>{`Health`}</a></h3>
    <p>{`Health of SNMP polling service. Null if SNMP polling is disabled.`}</p>
    <ul>
      <li parentName="ul">{`GOOD if SNMP service is up and running.`}</li>
      <li parentName="ul">{`POOR if SNMP service isn’t running.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      